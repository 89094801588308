// JS
import '@/js/initialize';
import 'bootstrap';
import 'admin-lte';
import 'datatables.net/js/jquery.dataTables';

// Core
import Vue from 'vue';
import PortalVue from 'portal-vue';
import ConfirmDialogModal from '@/js/admin/plugins/confirmDialogModal';
import $ from 'jquery';
import lang from '@/js/_lang';

import './core/errorHandling';
import { initShowHidePassword } from '@/js/common/utilities/showHidePassword';
import store from '@/js/admin/vuex/store';

import * as components from '@/js/admin/components';
import vanillaComponents from './vanilla-components/components';

const instantiateGlobalComponents = (components) => {
  Object.entries(components).forEach(([kebabCaseName, Class]) => {
    const querySelector = Class?.querySelector ?? `[data-component='${kebabCaseName}']`;
    document.querySelectorAll(querySelector).forEach((domElement) => new Class(domElement));
  });
};

window.$ = $;

Vue.filter('trans', (...args) => lang.get(...args));

Vue.mixin({
  data: () => ({ lang }),
  methods: {
    __: lang.get,
  },
});

Vue.use(PortalVue);
Vue.use(ConfirmDialogModal);

window.Event = new Vue();

Object.entries({ ...components })
  .forEach(([key, component]) => Vue.component(key, component));

window.app = new Vue({
  el: '#admin-app',
  store,
});

instantiateGlobalComponents(vanillaComponents);

initShowHidePassword();

$(() => {
  $('[data-toggle="tooltip"]').tooltip();
});
